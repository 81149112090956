/* eslint-disable no-nested-ternary */
import { selectors as searchSelectors } from 'redux/api/search/search';
import store from 'redux/store';
import PAGE_ANALYTICS from './page_analytics';
import { NO_VAR } from './analytics_constants';
import { formatSearchFilterTerm } from '../misc/misc';

const getFilterLevel = (selectedFilters = []) => {
  let filterLevel = '';
  selectedFilters.forEach((f) => {
    //Checkbox filters are represented here as string and freetext filters as arrays
    if (typeof f == 'string'){
      let fpath = f.split('/');
      fpath = fpath.filter(Boolean); // removes empty array items
      filterLevel += `${fpath.length}|`;
    } else {
      //filter level doesn't make sense for free text fields
      filterLevel = NO_VAR;
    }
  });
  return filterLevel;
};

export default {
  filterClearAll() {
    const type = searchSelectors.getSearchScope(store.getState());

    PAGE_ANALYTICS.setDefaultQueryProps();

    const adobe = {
      search: {
        ...window.digitalData.search,
        type
      },
      sinequaProfile: window.digitalData.sinequaProfile || NO_VAR
    };
    window.digitalData = Object.assign(window.digitalData, adobe);

    PAGE_ANALYTICS.track('filterClearAll');
  },

  applyFilter(filterTerm, options, searchfilterTerm, isAdvSearch, autoFilter = NO_VAR) { //event151
    const queryFilterTerm = filterTerm === 'advSearch' ? 'Adv Search' : filterTerm;
    const isKnowledgeTab = PAGE_ANALYTICS.getAnalyticsValFromUrlParameter('resultsView') !== NO_VAR;

    try {
      PAGE_ANALYTICS.setDefaultQueryProps();
      const filterLevel = getFilterLevel(options);
      const adobe = {
        search: {
          ...window.digitalData.search,
          filterTerm: queryFilterTerm,
          filterLabel: options.join('|'),
          filterLevel,
          searchfilterTerm: (filterTerm === 'advSearch' && isKnowledgeTab) ? formatSearchFilterTerm(searchfilterTerm) : (searchfilterTerm || NO_VAR),
          toolName: isAdvSearch ? 'Advanced Search' : NO_VAR,
        },
        autoFilter: autoFilter === true ? 'auto' : autoFilter,
        sinequaProfile: window.digitalData.sinequaProfile || NO_VAR,
        suggestedTopicTitle: NO_VAR,
        suggestedTopicID: NO_VAR,
        suggestedTopicGroup: NO_VAR,
        SERPview: PAGE_ANALYTICS.getAnalyticsValFromUrlParameter('resultsView')
      };
      window.digitalData = Object.assign(window.digitalData, adobe);
      PAGE_ANALYTICS.track('filterTermClicked');
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  },

  clearFilter(filterTerm, filterLabel, isAdvSearch, autoFilter = NO_VAR) {
    try {
      PAGE_ANALYTICS.setDefaultQueryProps();

      const adobe = {
        search: {
          ...window.digitalData.search,
          filterTerm,
          filterLabel,
          filterLevel: '1|', // need to implement the multilevel when functionality is done
          toolName: isAdvSearch ? 'Advanced Search' : NO_VAR
        },
        autoFilter: autoFilter === true ? 'auto' : autoFilter,
        sinequaProfile: window.digitalData.sinequaProfile || NO_VAR
      };

      window.digitalData = Object.assign(window.digitalData, adobe);
      PAGE_ANALYTICS.track('filterClear');
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  }
};
