import { useEffect, useRef } from 'react';
import CONFIG from 'config';
import NewTabIcon from 'assets/images/newTab.svg';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const handleStyledStatusMessages = (content) => {
  const regex = new RegExp(`${CONFIG.NAVI_CONTENT_TYPES.MATERIALS}|${CONFIG.NAVI_CONTENT_TYPES.EXPERTS}`);
  const materialsOrExperts = content.toLowerCase().match(regex);
  let prefix = '';
  let queryValue = '';
  let filterValue = '';

  if (materialsOrExperts) {
    const matchedString = materialsOrExperts[0];
    const quotedTexts = content.match(/"(.*?)"/g);

    if (quotedTexts) {
      queryValue = quotedTexts[0].replace(/"/g, ''); // Extract the first quoted text
      let filterMatches = content.match(/with filters (.*)$/);
      if(filterMatches?.length > 1) {
        filterValue = filterMatches[1].split(',').map(filter => encodeURIComponent(filter.trim())).join('&materialType=');
      }else {
        filterMatches = [];
        for(let i = 1; i < quotedTexts.length; i++) {
          filterMatches.push(quotedTexts[i].replace(/"/g, ''));
        }

        filterValue = filterMatches.map(filter => encodeURIComponent(filter.trim())).join('&materialType=');
      }
    }

    if (matchedString === CONFIG.NAVI_CONTENT_TYPES.MATERIALS) {
      prefix = `${CONFIG.UI_URL.KNOWLEDGE}?query=${encodeURIComponent(queryValue)}&enableAutoCorrect=${CONFIG.DEFAULT_AUTO_CORRECT}&sortingOrder=${CONFIG.DEFAULT_SORTERS.KNOWLEDGE}&dateRevised=${CONFIG.QUERY_FILTERS.PAST_5_Years}&resultsView=${CONFIG.QUERY_FILTERS.AI_VIEW}`;
      filterValue && (prefix += `&materialType=${filterValue}`);
    } else if (matchedString === CONFIG.NAVI_CONTENT_TYPES.EXPERTS) {
      prefix = `${CONFIG.UI_URL.EXPERTS}?query=${encodeURIComponent(queryValue)}&enableAutoCorrect=${CONFIG.DEFAULT_AUTO_CORRECT}&sortingOrder=${CONFIG.DEFAULT_SORTERS.EXPERT}&resultsView=${CONFIG.QUERY_FILTERS.AI_VIEW}`;
      filterValue && (prefix += '&onlyCCOProfiles=YES');
    }

    const modifiedText = content.replace(/"(.*?)"/, (match, p1) => {
      return `<a href="${prefix}" target="_blank"  title="Click here to go to search results page" class="status-msg-link">${p1}<img src="${NewTabIcon}" alt="new-tab" class="status-msg-icon"></a>`;
    });

    return modifiedText;
  }
  
  return content;
};

export const formatSearchFilterTerm = (inputArray) => {
  const prefix = 'Category -';
  const labels = ['Title', 'Description', 'Slides'];
  return `${prefix} ${inputArray.map((val, i) => val ? labels[i] : '').join(',')}`;
};