/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import CONFIG from 'config';
import { queryObjToQueryString, flattenQuery } from 'utils/string-mapper/string-mapper';
import * as utils from '@kdshared/okta-utils';
import { genAIConsumerOptions } from 'redux/api/casesv2Search/casesv2Search';

const isRetrievalAPI = CONFIG.FEATURE_TOGGLES.USE_GENAI_RETRIEVAL_API;
const sources = [];
const NUM_LOOPS = 2;
const NUM_PER_PAGE = isRetrievalAPI ? 20 : 50;
const OFFSET = 5;

export function getEntries(data) {
  return Object.keys(data).map((key) => [key, data[key]]);
}

export function createTableMap(data, fieldMap) {
  return data.map(getEntries).reduce(
    (tMap, rowKVs, rowIndex) =>
      rowKVs.reduce(
        (map, [key, value]) => {
          if (fieldMap.get(key) && value != null && value !== 'restricted') {
            const columnValues = map[key] || Array.from({ length: data.length }).map(() => '');
            columnValues[rowIndex] = value;
            if (key === 'source') {
              if (value === 'src_Cases') {
                columnValues[rowIndex] = 'Cases';
              } else if (value === 'src_Knwl') {
                columnValues[rowIndex] = 'Materials';
              } else if (value === 'src_Int') {
                columnValues[rowIndex] = 'Internal Doc';
              } else if (value === 'src_MSites') {
                const columnURLValues = map['url'] || Array.from({ length: data.length }).map(() => '');
                const columnkbCmsIdValues = map['kbCmsId'] || Array.from({ length: data.length }).map(() => '');
                const columnkpCmsIdValues = map['kpCmsId'] || Array.from({ length: data.length }).map(() => '');
                columnURLValues[rowIndex] =  columnkbCmsIdValues[rowIndex] || columnkpCmsIdValues[rowIndex];
                if (columnURLValues[rowIndex]) {
                  columnValues[rowIndex] = 'Internal Doc';
                }
              }
            } else if (key === 'functionalAllPAs' || key === 'industryAllPAs') {
              let columnValue = '';
              value.forEach((obj) => {
                columnValue += `${obj.topicNameAlias},`;
              });
              columnValue = columnValue.slice(0, -1);
              columnValues[rowIndex] = columnValue;
            } else if (key === 'allCaseTeams') {
              let columnValue = '';
              let currentPartner = '';
              let totalHours = 0;
              value.forEach((obj) => {
                columnValue += `${obj.firstName}${' '}${obj.lastName},`;
                totalHours += obj.timeAndBillingHours ? parseInt(obj.timeAndBillingHours, 10) : 0;
                if (obj.positionAsOfTitle.indexOf('Partner') > -1) {
                  currentPartner += `${obj.firstName}${' '}${obj.lastName},`;
                }
              });
              currentPartner = currentPartner.slice(0, -1);
              columnValue = columnValue.slice(0, -1);
              columnValues[rowIndex] = columnValue;

              const currentPartnerColValues = map['currentPartner'] || Array.from({ length: data.length }).map(() => '');
              currentPartnerColValues[rowIndex] = currentPartner;
              map['currentPartner'] = currentPartnerColValues;

              const currentTotalHoursColValues = map['totalHours'] || Array.from({ length: data.length }).map(() => '');
              currentTotalHoursColValues[rowIndex] = totalHours;
              map['totalHours'] = currentTotalHoursColValues;
            } else if (key === 'projectId') {
              columnValues[rowIndex] = CONFIG.KN_URL.CASE_URL(value);
            } else if (key === 'allAuthors') {
              let columnValue = '';
              value.forEach((obj) => {
                columnValue += `${obj.firstName}${' '}${obj.lastName},`;
              });
              columnValue = columnValue.slice(0, -1);
              columnValues[rowIndex] = columnValue;
            } else if (key === 'allDownloads') {
              let columnValue = 0;
              if (value && value.length > 0) {
                columnValue = value[0].noOfDownloads;
              }
              columnValues[rowIndex] = columnValue;
            } else if (key === 'allSubjects') {
              let columnValue = '';
              value.forEach((obj) => {
                columnValue += `${obj.subjectName || obj.topicNameAlias},`;
              });
              columnValue = columnValue.slice(0, -1);
              columnValues[rowIndex] = columnValue;
            } else if ((key === 'kbCmsId' || key === 'kpCmsId') && value && value.length > 0) {
              const columnSourceValues = map['source'] || Array.from({ length: data.length }).map(() => '');
              const columnURLValues = map['url'] || Array.from({ length: data.length }).map(() => '');
              if (columnSourceValues[rowIndex] === 'src_MSites') {
                columnURLValues[rowIndex] = value;
                if (columnURLValues[rowIndex]) {
                  columnSourceValues[rowIndex] = 'Internal Doc';
                }
              } else {
                columnURLValues[rowIndex] = CONFIG.CMS.KP_URL(value);
              }
              map['url'] = columnURLValues;
            } else if ((key === 'fpaRecommendedList' || key === 'ipaRecommendedList') && value && value.length > 0) {
              const columnURLValues = map['recommendedByPA'] || Array.from({ length: data.length }).map(() => '');
              columnURLValues[rowIndex] += value;
              map['recommendedByPA'] = columnURLValues;
            }
            map[key] = columnValues;
          }
          return map;
        },
        tMap,
      ),
    Object.create(null),
  );
}

export function createTableEntries(tableMap, fieldMap) {
  delete tableMap.kbCmsId;
  delete tableMap.kpCmsId;
  delete tableMap.fpaRecommendedList;
  delete tableMap.ipaRecommendedList;
  const keys = [...fieldMap.keys()];
  return getEntries(tableMap).map(([fieldName, fieldValues]) => ({
    fieldName: fieldMap.get(fieldName),
    fieldValues,
    position: keys.indexOf(fieldName)
  }));
}

function comparePosition(a, b) {
  return a.position - b.position;
}

function renderColumns(columnsWidth) {
  if (!columnsWidth || columnsWidth?.length === 0) {
    return '';
  }

  return `<colgroup>
            ${columnsWidth.map(width => (typeof width === 'number') ? `<col width=${width} style="width:${width*6}pt" />` : '<col />').join('')}
          </colgroup>`;
}

function escapeHtmlCharacters(value) {
  if (typeof value !== 'string') {
    return value;
  }

  return value.replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

export function renderTableHTMLText(data, fieldMap, columnsWidth) {
  if (data && data.length > 0) {
    const tableMap = createTableMap(data, fieldMap);
    const tableEntries = createTableEntries(tableMap, fieldMap);
    tableEntries.sort(comparePosition);
    const head = tableEntries.map(({ fieldName }) => escapeHtmlCharacters(fieldName))
      .join('</b></th><th><b>');
    const columns = tableEntries.map(({ fieldValues }) => fieldValues)
      .map((column) => column.map((value) => `<td>${escapeHtmlCharacters(value)}</td>`));
    const rows = columns.reduce(
      (mergedColumn, column) => mergedColumn
        .map((value, rowIndex) => `${value}${column[rowIndex]}`),
    );

    return `
      <table>
        ${renderColumns(columnsWidth)}
        <thead>
          <tr><th><b>${head}</b></th></tr>
        </thead>
        <tbody>
          <tr>${rows.join(`</tr>
          <tr>`)}</tr>
        </tbody>
      </table>
    `;
  }

  return null;
}

export function createXLSData(data, fieldMap, columnsWidth) {
  if (data && !data.length) return '';

  const content =
    `
    <html>
      <head>
        <meta charset="UTF-8">
      </head>
      <body>
        ${renderTableHTMLText(data, fieldMap, columnsWidth)}
      </body>
    </html>
    `;

  return content;
}

export function generateDataURI(content) {
  return `data:application/vnd.ms-excel;charset=utf-8,${encodeURIComponent(content)}`;
}

export function downloadFile(content, fileName = 'download') {
  const dataURI = generateDataURI(content);

  const anchor = document.createElement('a');
  anchor.href = dataURI;

  anchor.download = fileName;
  anchor.setAttribute('style', 'visibility:hidden');

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

export async function requestCaseData(query, pageSize, offset) {
  const newSource = axios.CancelToken.source();
  const caseQuery = flattenQuery(query);
  sources.push(newSource);

  if(isRetrievalAPI) {
    const options = genAIConsumerOptions();
    caseQuery.top_n = pageSize;
    caseQuery.from_n = offset + 1;
    caseQuery.data_repository = 'elastic';
    caseQuery.method = 'bm25';
    caseQuery.elastic_cluster = options.elastic_cluster;
    caseQuery.consumer_options = options.consumer_options;
    // Add caseQuery.query only when query.query is empty or undefined
    if (query.query === '' || query.query === undefined) {
      caseQuery.query = '';
      caseQuery.enableAutoCorrect = true;
    }
  } else {
    caseQuery.resultsPerPage = pageSize;
    caseQuery.resultsFromPage = offset + 1;
  }
  
  return axios.post(isRetrievalAPI ? CONFIG.API_URL.GENAI_ENBL_RETRIEVAL('') : CONFIG.API_URL.CASE_SEARCH_V2(''), caseQuery, { cancelToken: newSource.token });
}

export const mapGenAIEnblRetrievalResponse = (results) => {
  return results?.map((item) => {
    return {
      docId: item.crawl_item_id,
      docRank: item.rank,
      //per Venkat Paranjothi - We don't have globalrelevance in the new elastic, so please keep the value 1 for now.
      globalrelevance: 1,
      modified: item.last_update_date,
      source: item.contenttype,
      version: item.documentversion,
      officeName: item.office_name,
      caseNumber: item.case_number,
      companyId: item.client_id,
      officeCode: item.office_id,
      projectId: item.crawl_item_id,
      projectName: item.case_name,
      description: item.case_full_desc,
      industryAllPAs: item.allindustrypas?.map((pa) => {
        return({
          keywordTopic: pa.keywordtopic,
          topicId: pa.topic_id,
          topicNameAlias: pa.topic_name_alias,
          parentId: pa.parent_id,
          paRecommended: pa.key_material_flag,
          sort: pa.hierarchy_sort_order,
          fullPath: pa.path.replaceAll('>', '/')
        });
      }),
      functionalAllPAs: item.allfunctionalpas?.map((pa) => {
        return({
          keywordTopic: pa.keywordtopic,
          topicId: pa.topic_id,
          topicNameAlias: pa.topic_name_alias,
          parentId: pa.parent_id,
          paRecommended: pa.key_material_flag,
          sort: pa.hierarchy_sort_order,
          fullPath: pa.path.replaceAll('>', '/')
        });
      }),
      allSubjects: item.allsubjects?.map((subject) => {
        return({
          keywordTopic: subject.keywordtopic,
          topicId: subject.topic_id,
          topicNameAlias: subject.topic_name_alias,
          parentId: subject.parent_id,
          paRecommended: subject.key_material_flag,
          sort: subject.hierarchy_sort_order,
          fullPath: subject.path.replaceAll('>', '/')
        });
      }),
      shortDescription: item.case_short_desc,
      clientDescription: item.client_desc,
      caseHours: item.case_hours_by_staff,
      allCaseTeams: item.all_case_team?.map((person) => {
        return({
          hrEmployeeId: person.hremployeeid,
          firstName: person.firstname,
          lastName: person.lastname,
          // Per Venkat Paranjothi - This field is not in use.  Use HRID instead
          staffId: person.hremployeeid,
          email: person.email,
          isAlumni: person.isalumni,
          positionTitle: person.positiontitle,
          // 100 is default value to ensure end of order for positions not in mapping
          positionSortOrder: positionSortOrderMapping[person['position-as-of-title'].toLowerCase()] || 100,
          positionAsOfTitle: person['position-as-of-title'],
          timeAndBillingHours: item.case_hours_by_staff?.find((el) => el.hr_id == person.hremployeeid)?.tb_hours,
        });
      }),
      caseOpenDate: item.date_opened_actual,
      caseCloseDate: item.date_closed,
      caseType: item.case_type,
      clientName: item.client_name,
      parentCompany: item.parent_client_name,
      clientBU: item.clientbu,
      caseVignettes: item?.casevignettes?.map((vignette) => ({
        dateModified: vignette.lastupdatedate, 
        entitled: vignette.entitled, 
        fileName: vignette.filename, 
        imageName: vignette.imagename, 
        kpCmsId: vignette.kp_cms_id, 
        materialId: null, 
        attachmentId: null, 
        numberOfDownloads: vignette.downloadcount, 
        paRecommended: vignette.parecommended, 
        projectId: null
      })) || [],
      caseVignettesStatus: item.casevignettesstatus ? 'True' : 'False',
      kmsOwner: item.kms_owner,
      cco: item.cco,
      relatedCases: item.related_cases,
      region: item.region,
      geoRegion: item.georegion,
      textDescription: item.case_full_desc,
      clientBUDescription: item.client_bu_desc,
      subjectPAFilter: item.subjectpatopic.join('; '),
      knowledgeAssets: item.knowledgeassets
    };
  });
};

const positionSortOrderMapping = {
  'managing director and partner': 1,
  'partner and director': 2,
  'partner and associate director': 3,
  'associate director': 4,
  'partner': 5,
  'principal': 6,
  'project leader': 7,
  'consultant': 8,
  'associate': 9,
  'knowledge team': 10,
  'data & research services': 11,
  'pa mgt/operations': 12,
  'vs billable': 13,
  'human resources': 14,
  'temporary consulting team': 15,
  'platinion': 16,
  'bcg digital ventures': 17,
  'gamma': 18,
  'inverto': 19,
  'center for energy impact': 20,
  'information technology': 21,
  'summer consulting team': 22,
  'marketing': 23,
  'finance': 24,
  'operations': 25,
  'bcg brighthouse': 26,
  'expand': 27,
  'outside consulting team': 28,
  'bcg omnia': 29,
  'other consulting team': 30,
};

export async function exportCases(query, isUserRestricted) {
  const exportData = [];

  await Array(NUM_LOOPS).fill(1).reduce(async (acc, i) => {
    const sum = await acc;
    const batch = Array(OFFSET).fill()
      .map((_y, j) => requestCaseData(query, NUM_PER_PAGE, j + (OFFSET * sum)));
    await Promise.all(batch)
      .then((data) => data
        .map((d) => isRetrievalAPI ?  mapGenAIEnblRetrievalResponse(d.results) : d.doc)
        .filter((d) => d)
        .forEach((d) => exportData.push(d)));
    return sum + i;
  }, 0);

  const returnData = exportData.flat().map(data => {
    return {
      ...data,
      clientBUstring: (data?.clientBU && data?.clientBU !== 'NULL') ? `${data.clientBU}` : '',
      clientBUDescriptionString: (data?.clientBUDescription && data?.clientBUDescription !== 'NULL') ? `${data.clientBUDescription}` : '',
    };
  });
  const casesMapping = new Map(CONFIG.EXPORT.MAPPINGS.CASES);
  if (! isRetrievalAPI && isUserRestricted) {
    casesMapping.delete('clientBUstring');
  }
  const content = createXLSData(returnData, casesMapping);
  downloadFile(content, CONFIG.EXPORT.FILENAME.CASES);
  return returnData;
}

export async function requestMaterialData(query, API_URL, pageSize, offset) {
  const newSource = axios.CancelToken.source();
  sources.push(newSource);
  const materialQuery = flattenQuery(query);

  materialQuery.resultsPerPage = pageSize;
  materialQuery.resultsFromPage = offset + 1;
  materialQuery.skipPreviews = true;
  return axios.get(API_URL(queryObjToQueryString(materialQuery)), { cancelToken: newSource.token });
}

export async function exportMaterials(query, API_URL, filename) {
  const exportData = [];

  // This cycle will rate-limit multiple calls to the back-end, while wanting
  // to keep the parameters behind the number of calls per and how many calls
  // configurable to keep this flexible.
  // In this instance we want to make 10 API calls via requestCaseData(), 5 at a time.
  // Since these are all Promises, we can wait for them to finish before starting the
  // next cycle. But some weird JS-ness happens with async/await and looping
  //
  // Firstly, create an array of the number of cycles to run (NUM_LOOPS).
  // Using reduce(), we can build our Promise chain await until all the batch is complete.
  // Reference: https://zellwk.com/blog/async-await-in-loops/
  await Array(NUM_LOOPS).fill(1).reduce(async (acc, i) => {
  // The return result of .reduce here is a promise, so we await on the acc.
    const sum = await acc;
    // Similar process here, build an array, fill, and build a map of each promise request
    const batch = Array(OFFSET).fill()
      .map((_y, j) => requestMaterialData(query, API_URL, NUM_PER_PAGE, j + (OFFSET * sum)));
    // With each promise in this batch defined, await Promise.all() on them.
    await Promise.all(batch)
      .then((data) => data
      // When the data comes back from these calls, we want to
      // merge them all together in one array. So first, we want only
      // the `.doc` parts of each element in the response.
        .map((d) => d.doc)
      // Filter out the ones that are defined
        .filter((d) => d)
      // and push them on the final array.
        .forEach((d) => exportData.push(d)));
    // Since we need the offset of each call, sum them up to proceed.
    return sum + i;
  }, 0);

  const returnData = exportData.flat();
  const content = createXLSData(returnData, CONFIG.EXPORT.MAPPINGS.MATERIALS);
  downloadFile(content, filename);
  return returnData;
}

export async function requestPeopleData(qlFilters, pageSize, pageIndex, sort) {
  const newSource = axios.CancelToken.source();
  sources.push(newSource);

  return axios.post(
    CONFIG.API_URL.PEOPLE,
    {
      query: `
        query {
          searchFilter(
            aliasMatching:["title"]
            ${qlFilters}
            resultFilters:["jobPosition", "hostOfficeId", "hostOfficeRegion", "alumni"]
            dataSet:BCG_ALL
            limit: ${pageSize}
            offset:${pageIndex * pageSize}
            sortBy:[${sort.field}]
            sortOrder: ${sort.direction}
          ){
            totalCount
            employees {
                id
                profilePicture
                name
                firstName
                lastName
                title
                hostOfficeLocation {
                    id
                    name
                    timezone
                }
                hostOfficeRegion
                ${CONFIG.FEATURE_TOGGLES.ENABLE_PEOPLE_API_V2 ? 'primaryWorkPhone' : 'mobile'}
                ${CONFIG.FEATURE_TOGGLES.ENABLE_PEOPLE_API_V2 ? 'workEmail' : 'email'}
                slackId
                ${CONFIG.FEATURE_TOGGLES.ENABLE_PEOPLE_API_V2 ? 'lastUpdatedTimestamp' : 'lastUpdated'}
                status
                alumni
                assistants {
                  id
                  name
                  firstName
                  lastName
                  title
                  hostOfficeLocation {
                    name
                  }
                  hostOfficeRegion
                  ${CONFIG.FEATURE_TOGGLES.ENABLE_PEOPLE_API_V2 ? 'primaryWorkPhone' : 'mobile'}
                  ${CONFIG.FEATURE_TOGGLES.ENABLE_PEOPLE_API_V2 ? 'workEmail' : 'email'}
                  slackId
                  ${CONFIG.FEATURE_TOGGLES.ENABLE_PEOPLE_API_V2 ? 'lastUpdatedTimestamp' : 'lastUpdated'}
                  status
                  alumni
                }
            }
            searchFilters{
                field
                options: filters{
                    key
                    count
                }
            }
          }
        }`
    },
    {
      headers: {
        'x-api-key': CONFIG.PEOPLE_API_X_API_KEY,
        psId: utils.getPsId(),
        cancelToken: newSource.token
      }
    }
  );
}

export async function exportPhonebook(query, appliedFilters, buildFilterString, filename, sort = { field: '"firstName", "lastName"', direction: 'ASC' }, createUpdatedEmployeeWithAdditionalInfo, downloadIfEmpty = true) {
  let cleanTerm = query ? decodeURIComponent(query) : '';
  cleanTerm = cleanTerm.replace(/\s-\s|\s\|\s|\s&\s/ig, ' ').replace(new RegExp(/[+]/, 'igm'), '');
  const qlFilters = buildFilterString(cleanTerm, appliedFilters);

  const exportData = [];

  // This cycle will rate-limit multiple calls to the back-end, while wanting
  // to keep the parameters behind the number of calls per and how many calls
  // configurable to keep this flexible.
  // In this instance we want to make 10 API calls via requestCaseData(), 5 at a time.
  // Since these are all Promises, we can wait for them to finish before starting the
  // next cycle. But some weird JS-ness happens with async/await and looping
  //
  // Firstly, create an array of the number of cycles to run (NUM_LOOPS).
  // Using reduce(), we can build our Promise chain await until all the batch is complete.
  // Reference: https://zellwk.com/blog/async-await-in-loops/
  await Array(NUM_LOOPS).fill(1).reduce(async (acc, i) => {
  // The return result of .reduce here is a promise, so we await on the acc.
    const sum = await acc;
    // Similar process here, build an array, fill, and build a map of each promise request
    const batch = Array(OFFSET).fill()
      .map((_y, j) => requestPeopleData(qlFilters, NUM_PER_PAGE, j + (sum * OFFSET), sort));
    // With each promise in this batch defined, await Promise.all() on them.
    await Promise.all(batch)
      .then((data) => data
      // When the data comes back from these calls, we want to
      // merge them all together in one array. So first, we want only
      // the `.doc` parts of each element in the response.
        .map((d) => {
          //return d.data.searchFilter.employees.map((employee) => createUpdatedEmployeeWithAdditionalInfo(employee));
          return d?.data?.searchFilter?.employees;
        })
      // Filter out the ones that are defined
        .filter((d) => {
          return d;
        })
      // and push them on the final array.
        .forEach((d) => exportData.push(d)));
    // Since we need the offset of each call, sum them up to proceed.
    return sum + i;
  }, 0);

  const flattenedExportData = exportData.flat();
  if (!downloadIfEmpty && (!flattenedExportData || flattenedExportData.length === 0)) {
    return exportPhonebook(`${query}~`, appliedFilters, buildFilterString, filename, sort, createUpdatedEmployeeWithAdditionalInfo);
  }

  const returnData = flattenedExportData.map(data => {
    const workEmail = CONFIG.FEATURE_TOGGLES.ENABLE_PEOPLE_API_V2 ? data?.workEmail : data?.email;
    const primaryWorkPhone = CONFIG.FEATURE_TOGGLES.ENABLE_PEOPLE_API_V2 ? data?.primaryWorkPhone : data?.mobile;

    return {
      ...data,
      hostOfficeLocationName: (data?.hostOfficeLocation?.name && data.hostOfficeLocation.name !== 'NULL') ? `${data.hostOfficeLocation.name}` : '',
      workEmailString: (workEmail && workEmail !== 'NULL') ? `${workEmail}` : '',
      primaryWorkPhoneString: (primaryWorkPhone && primaryWorkPhone !== 'NULL') ? `${primaryWorkPhone}` : '',
      assistantsString: data?.assistants?.length > 0 ? data.assistants.map(assistant => assistant.name).join('; ') : ''
    };
  });

  const content = createXLSData(returnData, CONFIG.EXPORT.MAPPINGS.PEOPLE, CONFIG.EXPORT.COLUMNS_WIDTH.PEOPLE);
  downloadFile(content, filename);

  return returnData;
}

const buildExpertPhonebookQueryString = (firstNameLastNameBCGemailArray = []) => {
  let filterString = '';

  // title, hostOfficeId, primary work phone wont have the asterisks and hostOfficeId, searchTerm should retrieve only exact matches
  const filterKeywordFieldsExactMatch = [
  //  'firstName',
  //  'lastName',
    CONFIG.FEATURE_TOGGLES.ENABLE_PEOPLE_API_V2 ? 'workEmail' : 'email'
  ];
  //const filterKeywordFieldsPartialWordMatch = ['firstName', 'lastName', 'email'];

  filterString = // spacing is weird but this allows us to read it clearer when it's all concatenated
    firstNameLastNameBCGemailArray.map(obj => {
      return `
                      {
                  logicOperator: AND
                  subFilters: [
  ${filterKeywordFieldsExactMatch.map((k, idx) => `{ valueFilter: { field: "${k}=${obj[idx] ? obj[idx] : ''}" } }`).join(' ')}
                  ]
                      }\n`;
      //       return `
      //                       {
      //                   logicOperator: AND
      //                   subFilters: [
      //   ${filterKeywordFieldsPartialWordMatch.map((k, idx) => `{ valueFilter: { field: "${k}=${obj[idx] ? `${obj[idx].replace(new RegExp(/\s/, 'igm'), '* *')}*` : ''}" } }`).join(' ')}
      //                   ]
      //                       }\n`;
    }).join('');

  if (filterString) {
    filterString = // spacing is weird but this allows us to read it clearer when it's all concatenated
      `compositeFilter: {
                    logicOperator: OR
                    subFilters: [
 ${filterString}
                    ]
                 }`;
  }

  return filterString;
};

async function requestExpertsData(query, buildQuery, pageSize, pageIndex, hasAIAccess) {
  try {
    query = buildQuery(query, pageIndex, pageSize);

    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    return axios.post(
      CONFIG.API_URL.EXPERT_SEARCH(hasAIAccess ? 'search/export-search' : 'search/export-keyword-search'),
      query,
      {
        cancelToken: newSource.token,
        headers: { 'x-api-key': CONFIG.EXPERT_SEARCH_X_API_KEY }
      },

    );
  } catch (error) {
    if (error.cancelled) return;
  }
}

export async function exportExperts(query, buildQuery, filename, hasAIAccess) {
  const employeesData = [];
  const exportData = [];

  // This cycle will rate-limit multiple calls to the back-end, while wanting
  // to keep the parameters behind the number of calls per and how many calls
  // configurable to keep this flexible.
  // In this instance we want to make 10 API calls via requestCaseData(), 5 at a time.
  // Since these are all Promises, we can wait for them to finish before starting the
  // next cycle. But some weird JS-ness happens with async/await and looping
  //
  // Firstly, create an array of the number of cycles to run (NUM_LOOPS).
  // Using reduce(), we can build our Promise chain await until all the batch is complete.
  // Reference: https://zellwk.com/blog/async-await-in-loops/
  const firstNameLastNameArrayOfArray = [];
  const phoneBooks = [];

  await Array(NUM_LOOPS).fill(1).reduce(async (acc, i) => {
  // The return result of .reduce here is a promise, so we await on the acc.
    const sum = await acc;

    // Similar process here, build an array, fill, and build a map of each promise request
    const batch = Array(OFFSET).fill()
      .map((_y, j) => requestExpertsData(query, buildQuery, NUM_PER_PAGE, j + (sum * OFFSET), hasAIAccess));

    // With each promise in this batch defined, await Promise.all() on them.
    await Promise.all(batch)
      .then((data) => data
      // When the data comes back from these calls, we want to
      // merge them all together in one array. So first, we want only
      // the `.doc` parts of each element in the response.
        .map((d, k) => {
          firstNameLastNameArrayOfArray.push([]);
          return d?.searchResults?.map((r) => {
            const email = r.profile?.bcgEmailAddress ? r.profile.bcgEmailAddress : (r.bcgEmailAddress ? r.bcgEmailAddress : '');
            //Must match 'filterKeywordFieldsPartialWordMatch' above!
            firstNameLastNameArrayOfArray[k].push([
              //r.profile.firstName,
              //r.profile.lastName,
              email
            ]);

            return {
              ...r,
              ...r.profile,
              relevancyDetails: r.relevancyDetails,
              skills: r.profile?.skills?.map(s=>({...s, tagName:s.topicPath?.replace(/\\\//g,'&#47;').replace(/>/g,'/'), originalTagName:s.topicName})),
              expertise: r.profile?.expertise?.map(s=>({...s, tagName:s.topicPath?.replace(/\\\//g,'&#47;').replace(/>/g,'/'), originalTagName:s.topicName}))
            };
          });
        })
      // Filter out the ones that are defined
        .filter((d, idx) => {
          phoneBooks.push(
            requestPeopleData(
              buildExpertPhonebookQueryString(firstNameLastNameArrayOfArray[idx + (sum * OFFSET)]),
              firstNameLastNameArrayOfArray[idx + (sum * OFFSET)].length,
              0,
              { field: '"firstName", "lastName"', direction: 'ASC' }
            )
          );
          return d;
        })
      // and push them on the final array.
        .forEach((d) => exportData.push(d)));
    // Since we need the offset of each call, sum them up to proceed.
    return sum + i;
  }, 0);

  await Promise.all(phoneBooks)
    .then((data) => data
    // When the data comes back from these calls, we want to
    // merge them all together in one array. So first, we want only
    // the `.doc` parts of each element in the response.
      .map((d, idx) => {
        //return d.data.searchFilter.employees.map((employee) => createUpdatedEmployeeWithAdditionalInfo(employee));
        return d?.data?.searchFilter?.employees;
      })
    // Filter out the ones that are defined
      .filter((d) => {
        return d;
      })
    // and push them on the final array.
      .forEach((d) => employeesData.push(d)));

  const employeesDataFlattened = employeesData.flat();
  const emailsMap = new Map(employeesDataFlattened.map(e => [CONFIG.FEATURE_TOGGLES.ENABLE_PEOPLE_API_V2 ? e.workEmail : e.email, e]));

  const exportDataFlattened = exportData.flat();

  const returnData = exportDataFlattened.map((data) => {
    let hostOfficeRegion;
    let assistantsEmails;
    let profileLink;
    let profile;

    if (data.bcgEmailAddress && emailsMap.has(data.bcgEmailAddress)) {
      profile = emailsMap.get(data.bcgEmailAddress);
      if (profile.lastName === data.lastName && profile.firstName === data.firstName) {
        hostOfficeRegion = profile['hostOfficeRegion'];
        assistantsEmails = profile['assistants']?.filter(assistant => { 
          const workEmail = CONFIG.FEATURE_TOGGLES.ENABLE_PEOPLE_API_V2 ? assistant.workEmail : assistant.email;
          return workEmail && workEmail !== 'NULL';
        }).map(assistant => CONFIG.FEATURE_TOGGLES.ENABLE_PEOPLE_API_V2 ? assistant.workEmail : assistant.email).join('; ');
        profileLink = CONFIG.KN_URL.AUTHOR_PROFILE(profile['id']);
      }
    } else {
      //console.log(`Missing person: ${data.name}; ${data.bcgEmailAddress} from people search.`);
      if (!data.bcgEmailAddress) {
        const found = employeesDataFlattened.filter(e => { 
          const workEmail = CONFIG.FEATURE_TOGGLES.ENABLE_PEOPLE_API_V2 ? e.workEmail : e.email;
          return e.lastName === data.lastName && e.firstName === e.firstName && (workEmail === 'NULL' || !workEmail);
        });
        if (found && found.length > 0) {
          hostOfficeRegion = found[0]['hostOfficeRegion'];
          assistantsEmails = found[0]['assistants']?.filter(assistant => { 
            const workEmail = CONFIG.FEATURE_TOGGLES.ENABLE_PEOPLE_API_V2 ? assistant.workEmail : assistant.email;
            return workEmail && workEmail !== 'NULL';
          }).map(assistant => CONFIG.FEATURE_TOGGLES.ENABLE_PEOPLE_API_V2 ? assistant.workEmail : assistant.email).join('; ');
          profileLink = CONFIG.KN_URL.AUTHOR_PROFILE(found[0]['id']);
        }
      }
    }

    return {
      ...data,

      //positionString: `${data.relevancyDetails.position}`,
      businessTitleString: `${data.businessTitle ? data.businessTitle : ''}`,
      jobFamilyGroupString: `${data.jobFamilyGroup ? data.jobFamilyGroup : ''}`,

      bcgOrganizationString: data.organizationName ? data.organizationName : '',

      // expertTypeString: data.expertise ? Array.from(new Set(data.expertise.map(rpe => rpe.topicName))).sort().join('; ') : '',
      expertTypeString: data.expertType ?
        data.expertType :
        [
          data.isCCO ? 'CCO' : null,
          data.isECT ? 'ECT' : null,
          data.isKnowledgeTeam ? 'KT' : null,
          data.isSeniorAdvisor ? 'SA' : null
        ].filter(v => v !== null).join('; '),

      hostOfficeString: data.hostOffice ? data.hostOffice : '',
      regionString: hostOfficeRegion && hostOfficeRegion !== 'NULL' ? hostOfficeRegion : '',
      profileURLstring: profileLink ? profileLink : '',
      profileSummaryString: `${data.summary ? data.summary.replace('&nbsp;', ' ').replace('\\n', ' ') : ''}`,
      bcgEmailAddressString: data.bcgEmailAddress ? `${data.bcgEmailAddress}` : '',
      businessMobileString: data.businessMobile ? `${data.businessMobile}` : '',
      emailAssistantString: assistantsEmails ? assistantsEmails : '',
      relatedCasesString: `${location.protocol}//${location.host}${CONFIG.UI_URL.CASE}?${CONFIG.CASE_QUERY_PARAMS.QUERY}=${encodeURIComponent(query.query)}&${CONFIG.EXPERT_QUERY_PARAMS.SORTING_ORDER}=dateOpened.desc&enableAutoCorrect=true&${CONFIG.CASE_QUERY_PARAMS.CASETEAMMEMBERWITHHRID}=${encodeURIComponent(`${data.firstName} ${data.lastName} ${data.hrEmployeeId}`)}&resultsView=list`,
      relatedMaterialsString: `${location.protocol}//${location.host}${CONFIG.UI_URL.KNOWLEDGE}?${CONFIG.QUERY_PARAMS.QUERY}=${encodeURIComponent(query.query)}&enableAutoCorrect=true&${CONFIG.EXPERT_QUERY_PARAMS.SORTING_ORDER}=relevance.desc&${CONFIG.CASE_QUERY_PARAMS.DOCUPDATEDDATERANGE}=Past%20Five%20Years&${CONFIG.QUERY_PARAMS.AUTHOR}=${encodeURIComponent(`${data.firstName} ${data.lastName}`)}&resultsView=list`,
      // Do not remove yet. This is an alternative for having 2 columns.
      // companiesPriorToJoiningBCGString: data.work ? data.work.map(work => `${work.title ? work.title : ''} at ${work.company ? work.company : ''}`).join('; ') : ''
      companiesPriorToJoiningBCGString: data.work ? data.work.map(work => work.company ? work.company : '').join('; ') : '',
      titlesPriorToJoiningBCGString: data.work ? data.work.map(work => work.title ? work.title : '').join('; ') : ''
    };
  });

  const expertMappings = new Map(CONFIG.EXPORT.MAPPINGS.EXPERTS);
  expertMappings.set('relatedCasesString', `Related Cases to ${query.query ? '*'+query.query.replace(/^\w/, c => c.toUpperCase())+'*' : ' '}`);
  expertMappings.set('relatedMaterialsString', `Related Materials to ${query.query ? '*'+query.query.replace(/^\w/, c => c.toUpperCase())+'*' : ' '}`);

  const content = createXLSData(returnData, expertMappings, CONFIG.EXPORT.COLUMNS_WIDTH.EXPERTS);
  downloadFile(content, filename);

  return returnData;
}